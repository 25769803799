import { useToast } from 'vue-toastification'
import getAllRoutes from './AppApiRoutes'
import { useApiErrorsStore, fetchToApiError } from '~~/store/api_errors'
const materialRoutes = getAllRoutes().materialsRoutes
export const useWishlist = (materialId: number, status = false) => {
  const toast = useToast()
  const wishlist = ref<boolean>(status)
  const { t } = useI18n()

  async function toggleWishlist(): Promise<boolean> {
    const { status } = useAuth()

    if (status.value !== 'authenticated') {
      toast.error(t('login_required'), { timeout: 1000 })
      return false
    }

    try {
      await $fetch(getAllRoutes().materialsRoutes.toggleFavServiceMaterialByServer, {
        method: 'POST',
        body: {
          app_locale: useNuxtApp().$i18n?.locale?.value,
          service_id: materialId,
          service_type: 'App\\Models\\Material'
        }
      })

      wishlist.value = !wishlist.value

      await toast.success(
        wishlist.value
          ? t('add_done', { to: t('to'), name: t('wishlist') })
          : t('remove_done', { from: t('from'), name: t('wishlist') }),
        { timeout: 1000 }
      )

      return true
    } catch (error) {
      toast.error(t('add_failed'), { timeout: 1000 })
      return false
    }
  }

  return {
    wishlist,
    toggleWishlist
  }
}

export const useRate = (materialId: number) => {
  const toast = useToast()
  const { t } = useI18n()
  const { addApiError, deleteApiError } = useApiErrorsStore()
  const loadingRate = ref<boolean>(false)
  async function rate(stars: number, noteUser: string): Promise<boolean> {
    const { status } = useAuth()

    if (status.value !== 'authenticated') {
      toast.error(t('login_required'), { timeout: 1000 })
      return false
    }

    try {
      deleteApiError('rate_material')
      loadingRate.value = true
      await $fetch(materialRoutes.rateMaterialByServer, {
        method: 'POST',
        body: {
          app_locale: useNuxtApp().$i18n?.locale?.value,
          material_id: materialId,
          rating: stars,
          note: noteUser
        }
      })

      toast.success(t('rate_done'), { timeout: 1000 })
      loadingRate.value = false
      return true
    } catch (error) {
      toast.error(t('add_failed'), { timeout: 1000 })
      loadingRate.value = false
      addApiError(fetchToApiError(error), 'rate_material')
      return false
    }
  }

  return {
    loadingRate,
    rate
  }
}

export const useReport = (materialId: number) => {
  const toast = useToast()
  const { t } = useI18n()

  async function report(text: string): Promise<boolean> {
    const { status } = useAuth()

    if (status.value !== 'authenticated') {
      toast.error(t('login_required'), { timeout: 1000 })
      return false
    }

    try {
      await $fetch(materialRoutes.reportMaterialByServer, {
        method: 'POST',
        body: {
          app_locale: useNuxtApp().$i18n?.locale?.value,
          service_id: materialId,
          service_type: 'App\\Models\\Material',
          report_content: text
        }
      })

      toast.success(t('report_done'), { timeout: 1000 })
      return true
    } catch (error) {
      toast.error(t('report_failed'), { timeout: 1000 })
      return false
    }
  }

  return report
}

export default function useCart() {
  const quantity = ref()
  const availableInCart = ref()

  return {
    quantity,
    availableInCart
  }
}

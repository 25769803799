<template>
  <div
    class="flex flex-col w-full p-2 md:w-1/2 lg:ltr:pl-10 lg:rtl:pr-10 text-mevcut-light"
  >
    <div class="flex items-center text-xl font-semibold my-2">
      <h1>{{ material.name }}</h1>
      <div
        v-if="material.is_featured"
        class="mx-2 p-1 bg-text_alt_color rounded-full"
      >
        <v-icon :size="24" class="block text-nav_color">
          <IconsBadgeCheck />
        </v-icon>
      </div>

      <div class="flex-auto"></div>

      <div
        v-if="layout == 'platform'"
        class="flex items-center rounded-full p-1 border bg-bg_color"
      >
        <button
          class="mx-1"
          :class="{ 'text-nav_color': wishlist }"
          :aria-label="t('add_material', { to: t('wishlist') })"
          @click="toggleFavouriteLocal"
        >
          <v-icon
            :icon="
              wishlist ? 'fa: fa-solid fa-heart' : 'fa: fa-regular fa-heart'
            "
            :size="24"
            class="block"
          />
        </button>

        <MaterialsRateDropdown
          :average-rate="material.average_rate"
          :material-id="material.id"
          @refresh-page="emit('refresh-page')"
        />
      </div>
    </div>

    <MevcutLink
      v-if="material.store"
      :to="storeRoute(material.store)"
      class="flex items-center group my-2 ltr:mr-auto rtl:ml-auto"
      :aria-label="t('aria_page_label', { page: material.store.name })"
    >
      <v-icon icon="fa: fa-solid fa-store" :size="20" class="opacity-90" />
      <div class="mx-2 font-medium text-text_color group-hover:underline">
        {{ material.store.name }}
      </div>
    </MevcutLink>

    <div
      v-if="material.store?.price_visible"
      class="flex my-2"
      :class="material.simple_unit_style ? 'items-start' : 'items-center'"
    >
      <v-icon :size="20" class="opacity-90">
        <IconsTagPrice />
      </v-icon>

      <div v-if="material.simple_unit_style">
        <div
          :dir="material.currencyIsRtl ? 'rtl' : 'ltr'"
          class="mx-2 text-lg font-medium text-text_color"
        >
          <span v-if="material.discountPrice">
            {{ material.discountPrice }}
          </span>
          <span v-else>{{ material.stringPrice }}</span>
        </div>

        <div
          v-if="material.discountPrice"
          class="mt-0.5 line-through text-mevcut-light text-sm"
          :dir="material.currencyIsRtl ? 'rtl' : 'ltr'"
        >
          {{ material.stringPrice }}
        </div>

        <div
          v-if="material.discountRate"
          dir="ltr"
          class="bg-button_color text-text_alt_color py-1 px-2 font-light leading-none rounded-lg mx-2"
        >
          -{{ parseInt(material.discountRate) }}%
        </div>
      </div>
      <div
        v-else
        :dir="material.currencyIsRtl ? 'rtl' : 'ltr'"
        class="mx-2 text-lg font-medium text-text_color"
      >
        {{ totalPrice }}
      </div>
    </div>

    <MaterialsShowMarkdownSummery
      v-if="material.summary"
      :content="material.summary"
    />

    <div class="border-b border-text_color opacity-25"></div>

    <!-- Summary -->
    <div v-if="material.extra_attributes?.length" class="my-2">
      <div class="flex items-center">
        <v-icon :size="20" class="opacity-90">
          <IconsViewDetails />
        </v-icon>

        <div class="font-medium text-secondary mx-2">
          {{ t('more_details') }}
        </div>
      </div>

      <div
        v-for="(attribute, index) in material.extra_attributes"
        :key="index"
        class="flex space-x-2 space-x-reverse text-sm text-mevcut-light"
      >
        <div class="font-medium">
          {{ attribute.name ? attribute.name + ':' : '' }}
        </div>
        <div class="font-light">{{ attribute.desc || '' }}</div>
      </div>

      <div class="border-b border-text_color opacity-25 mt-2"></div>
    </div>

    <div v-if="material.properties_lookup_values?.length" class="mt-2">
      <div class="flex items-center">
        <v-icon icon="mdi mdi-select-group" :size="20" class="opacity-90" />
        <div class="font-medium text-secondary mx-2">
          {{ t('optional_specification') }}
        </div>
      </div>

      <MaterialsShowPropertiesLookupValuesNormal
        :material="material"
        @properties-lookup-selected="propertiesLookupSelected = [...$event]"
      />
      <MaterialsShowPropertiesLookupValuesEffectPrices
        :key="`EffectPrices-${material.id}`"
        :material="material"
        @update-material="emit('refresh-page', $event)"
      />
      <div class="border-b border-text_color opacity-25 mt-2"></div>
    </div>

    <!-- addons -->
    <div v-if="material.addons?.length" class="mt-2">
      <div class="flex items-center">
        <v-icon icon="mdi mdi-view-grid-plus" :size="20" class="opacity-90" />
        <div class="font-medium text-secondary mx-2">
          {{ t('additional_attributes') }}
        </div>
      </div>

      <div class="max-h-[13rem] overflow-y-auto">
        <MaterialsShowAddonsAttribute
          :material="material"
          @addons-attribute="updateSelectedAddons"
          @price="addonAttributePrice = $event"
        />
      </div>

      <div class="border-b border-text_color opacity-25 mt-2"></div>
    </div>

    <div class="my-auto"></div>
    <MaterialsShowButtonCartMobile
      v-if="isMobileOrTablet && !isDialouge"
      v-model="quantity"
      class="fixed w-screen bottom-0 z-40 rtl:translate-x-2 ltr:-translate-x-2"
      :total-price="totalPrice"
      :total-price-with-out-discount="totalPriceWithOutDiscount"
      :material="material"
      :loading="loading"
      @open-dialouges="toggleOpenDialouges"
    />
    <MaterialsShowButtonCartDesktop
      v-else
      v-model="quantity"
      :total-price="totalPrice"
      :is-dialouge="isDialouge"
      :total-price-with-out-discount="totalPriceWithOutDiscount"
      :material="material"
      :loading="loading"
      @open-dialouges="toggleOpenDialouges"
    />

    <MaterialsDialougeSelectBranchForMaterial
      v-model="openDialougeSelectBranch"
      :branches="material.branches"
      @select-branch="
        material.store?.cart_visible
          ? addItemLocal(undefined, $event)
          : useContactViaWhatsAppForMaterials(material, undefined, $event)
      "
    />
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import type {
  Material,
  PropertiesLookupValue
} from '~~/composables/useMenuModel'
const props = defineProps<{ material: Material; isDialouge?: boolean }>()
const emit = defineEmits(['refresh-page', 'added-cart-success'])
const { layout } = useDomainHost()
const { toggleValueFavourite } = useCacheSession()
const openDialougeSelectBranch = ref(false)
const { isMobileOrTablet } = useDevice()
const { storeRoute } = useDomainState()
const { toggleAddwithAddonsAttribute } = useCartStore()
const toast = useToast()
const loading = ref(false)
const quantity = ref(1)
const addonsAttributeSelected = ref<[]>([])
const propertiesLookupSelected = ref<PropertiesLookupValue[]>([])
const addonAttributePrice = ref(0)
const { t } = useI18n()
const { wishlist, toggleWishlist } = useWishlist(
  props.material.id,
  props.material.hasFavoritedByAgent
)
async function toggleFavouriteLocal() {
  if (await toggleWishlist()) {
    toggleValueFavourite(props.material.id, wishlist.value)
  }
}
const branchIdQuery = computed(() => {
  return useBranchId()
})
const branchQuery = computed(() => {
  return props.material.branches?.find((el) => el.id === branchIdQuery.value)
})
function updateSelectedAddons($event: any) {
  addonsAttributeSelected.value = $event
}
const enableOpenDialougeSelectBranch = computed(() => {
  return props.material.branches?.length
    ? branchQuery.value === undefined && props.material.branches?.length > 1
    : false
})
async function toggleOpenDialouges(event?: Event) {
  const currentBranch = getCurrentBranch()
  if (enableOpenDialougeSelectBranch.value) {
    openDialougeSelectBranch.value = true
  } else if (props.material.store?.cart_visible) {
    await addItemLocal(event, currentBranch)
  } else {
    await useContactViaWhatsAppForMaterials(
      props.material,
      undefined,
      currentBranch
    )
  }
}
function getCurrentBranch() {
  let brancheTemp = props.material.branches?.length
    ? props.material.branches[0]
    : undefined
  if (branchQuery.value?.id) {
    brancheTemp = branchQuery.value
  }

  return brancheTemp
}

const totalPrice = computed(() => {
  const price =
    quantity.value * (props.material.discount || props.material.finalPrice)

  return useStringPrice(
    price + addonAttributePrice.value,
    props.material.currencyCode
  )
})

const totalPriceWithOutDiscount = computed(() => {
  if (!props.material.discount) {
    return null
  }

  const price = quantity.value * props.material.finalPrice

  return useStringPrice(
    price + addonAttributePrice.value,
    props.material.currencyCode
  )
})

async function addItemLocal(event?: Event, branch?: BranchStore) {
  event?.preventDefault()

  loading.value = true

  if (
    await toggleAddwithAddonsAttribute(
      props.material,
      quantity.value,
      addonsAttributeSelected.value,
      undefined,
      [
        ...propertiesLookupSelected.value,
        ...(props.material.propertiesLookupValuesCustom
          ?.propertiesLookupValuesEffectPricesOriginal ?? [])
      ],
      branch?.id
    )
  ) {
    toast.success(t('add_done', { to: t('to'), name: t('cart') }), {
      timeout: 1000
    })
    emit('added-cart-success')
  }
  loading.value = false
}
</script>
